<template>
  <a-card class="contact-form-card">
    <ValidationObserver ref="observer">
      <a-form
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(handleSubmit)"
      >
        <h2 class="title">{{ labels.title }}</h2>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :lg="24" :md="12" :sm="24" :xs="24">
            <Input
              v-model="form.name"
              rules="required"
              vid="name"
              :placeholder="labels.form.name.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.name.label"
              icon="user"
              @input="onlyAlfa($event, 'name')"
            />
          </a-col>
          <a-col :lg="24" :md="12" :sm="24" :xs="24">
            <Input
              v-model="form.email"
              rules="required|email"
              vid="email"
              :placeholder="labels.form.email.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.email.label"
              icon="mail"
            />
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :span="24">
            <Input
              v-model="form.subject"
              rules="required"
              vid="subject"
              :placeholder="labels.form.subject.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.subject.label"
              icon="edit"
              @input="onlyAlfa($event, 'subject')"
            />
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :span="24">
            <TextArea
              v-model="form.message"
              rules="required"
              vid="message"
              :placeholder="labels.form.message.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.message.label"
              :autoSize="{ minRows: 4, maxRows: 6 }"
            />
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :span="24">
            <!-- Subscribe button -->
            <a-form-item style="text-align: right">
              <a-button
                type="default"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting"
                size="large"
                class="btn-send"
                @click.prevent="validate().then(handleSubmit)"
              >
                <span>{{ labels.form.submit }}</span>
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </ValidationObserver>
  </a-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import _ from "lodash";
import Input from "@/components/core/VeeValidateForm/Input";
import TextArea from "@/components/core/VeeValidateForm/TextArea";
import labels from "@/utils/labels";
import { mapActions } from "vuex";
const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};
export default {
  name: "ContactUsForm",
  components: {
    ValidationObserver,
    Input,
    TextArea,
  },
  data() {
    return {
      form: _.cloneDeep(initialState),
      submitting: false,
      labels: {
        ...labels.contactUsForm,
        appName: labels.appName,
        unknownServerError: labels.unknownServerError,
      },
    };
  },
  methods: {
    ...mapActions(["sendContactUsEmail"]),
    async handleSubmit() {
      try {
        this.submitting = true;
        await this.sendContactUsEmail(this.form);
        this.$success({
          title: this.labels.form.success,
          centered: true,
          // JSX support
          content: (
            <div>
              <p>
                {this.form.name}, <br />
                {this.labels.form.successEmail}
              </p>
            </div>
          ),
          onOk: () => {
            this.form = _.cloneDeep(initialState);
            this.$refs.observer.reset();
          },
        });
      } catch (error) {
        this.$notification["error"]({
          message: `${this.labels.error}`,
          description: this.labels.errorMessage,
        });
      } finally {
        this.submitting = false;
      }
    },
    async onlyAlfa(value = "", field) {
      this.form[field] = await value.replace(/[^a-zA-Z\s]/g, "");
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.contact-form-card {
  background-color: color(c-secondary);
  border-color: color(c-secondary);
  padding: 3.125rem;
  border-radius: 10px;
  @media screen and (max-width: $screen-mobile) {
    padding: 2.125rem 1rem;
  }
  .title {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-form label {
    font-size: 0.875rem;
    color: color(--white);
    font-family: "Roboto", sans-serif;
  }
  .ant-input {
    background-color: color(c-primary);
    height: 3.75rem;
    color: color(--white);
    &:active,
    &:focus,
    &:hover {
      border-color: color(c-hover);
    }
  }
  .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 2.8125rem;
    }
    .ant-input-prefix {
      color: color(--white);
      font-size: 1.375rem;
      font-family: "Roboto", sans-serif;
    }
    .ant-input-clear-icon,
    .ant-input-textarea-clear-icon {
      color: color(--white);
    }
  }

  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: color(c-primary);
  }

  .btn-send {
    color: color(c-primary);
    background-color: color(c-hover);
    border-color: color(c-hover);
    height: 51px;
    padding: 0 1.75rem;
    @media screen and (max-width: 596px) {
      width: 100% !important;
    }
  }
}

input:-internal-autofill-selected {
  background-color: color(c-primary) !important;
  border-color: color(c-hover) !important;
  color: black !important;
}
</style>
