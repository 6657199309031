<template>
  <a-layout class="contact">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="center" :gutter="[16, 16]">
        <a-col :md="16">
          <ContactUsForm />
        </a-col>
      </a-row>
    </div>
  </a-layout>
</template>
<script>
import ContactUsForm from "@/components/ContactUsForm.vue";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
export default {
  components: { TitleBreadcrumb, ContactUsForm },
  name: "Contact",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.contactUsForm.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.contactUsForm.subtitle,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.contact {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
}
</style>
